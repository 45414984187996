/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-logo {
  max-width: 90%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.App-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
